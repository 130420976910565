import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "editing-an-event"
    }}>{`Editing an Event`}</h1>
    <p>{`You can edit an event after it has been approved and published.`}</p>
    <Alert kind="warning" mdxType="Alert">This functionality is exclusively available to users with Manager or Admin level access permissions.</Alert>
    <h2 {...{
      "id": "edit-events"
    }}>{`Edit Events`}</h2>
    <ol>
      <li parentName="ol">{`Select the event you want to edit`}</li>
      <li parentName="ol">{`Select 'Edit Event'`}</li>
      <li parentName="ol">{`Make changes to the event information`}</li>
      <li parentName="ol">{`If this is a recurring event you can select whether to edit just this specific event or all events in the series`}</li>
      <li parentName="ol">{`Select 'Update Event'`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/K-or_LOZm1w" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <Alert kind="info" mdxType="Alert">You will only be able to edit events if you have Manager access or higher.</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      